












































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { TemplateDetail } from "@/request/doctor";
import {
  IfEmpty,
  hideContent,
} from "@/components/template-transfer-pc/template-transfer/common";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import TemplateTransfer from "@/components/template-transfer-pc/template-transfer/template-transfer.vue";
import LeaveConfirm from "@/components/dialogs/leaveConfirmDialog.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    TemplateTransfer,
    LeaveConfirm,
  },
})
export default class Name extends Vue {
  private ifShowSaveConfirm: any = false;
  private noSaveText: any = "不保存，切换";
  private saveConfirmType: any = "";
  private redIndex: any = -1; // 需要标红提示未填的index
  private leftNavs: any = [];
  private content: any = {};
  private get currentXuqiu() {
    return this.$store.state.currentXuqiu;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private getTemplates() {}
  private edit() {
    this.$store.commit("updateIfCompose", true);
  }
  // 保存确认弹框保存
  private changeSave() {}
  // 保存确认弹框不保存,切换
  private changeConfirmed() {}
  private save() {}
  private updateTemplate() {}
  /**
   * @description 隐藏或者显示某些相关题目
   */
  private changeHide(e: any) {
    const data = this.content["模板"];
    hideContent(e, data);
  }
  private selectFuwu(item: any) {
    const params = {
      template_id: item.template_id,
    };
    TemplateDetail(this, params).then((res: any) => {
      this.content = res;
    });
  }
  private delOne(e: any) {
    this.$confirm(
      "您确定需要删除服务么？删除后该服务数据将全部清空。",
      "删除",
      {
        customClass: "commonConfirm",
      }
    )
      .then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      })
      .catch(() => {});
  }
  private getLeftNav() {
    this.leftNavs = [
      {
        template_id: "tem636b3ec38b032200b97be559",
        名称: "既往冠心病史",
        status: "0",
      },
      {
        template_id: "tem636b3ec38b032200b97be55f",
        名称: "危险因素",
        status: "5",
      },
      {
        template_id: "tem636b3ec38b032200b97be55a",
        名称: "既往史",
        status: "10",
      },
      {
        template_id: "tem636b3ec38b032200b97be55c",
        名称: "目前口服用药",
        status: "0",
      },
      {
        template_id: "tem636b3ec38b032200b97be564",
        名称: "症状体征",
        status: "0",
      },
    ];
    // 默认选中第一个
    this.selectFuwu(this.leftNavs[0]);
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.ifCompose) {
      this.saveConfirmType = "离开";
      this.noSaveText = "不保存，离开";
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private mounted() {
    this.getLeftNav();
  }
}
